import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";
//import {Navbar, Nav, Container, NavDropdown, Dropdown} from 'react-bootstrap'

import Logo from './logo.svg';  //  src/images

function WelcomeNote(props) {
    


  
    return (




		


   
    <div className="top-desc-container">
		<div id="vision-statement">Risk assessment and mitigation are key to a successful investment strategy</div>
    <div id="vision-desc">Every family and every investor has different needs. We do not believe in one-size-fits-all investment strategies.<br/>We tailor each and every financial solution we offer to fit our clients' unique needs.</div>
	</div>
  
		
	 

	  

	



);
}

export default WelcomeNote;
