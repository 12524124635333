import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";
//import {Navbar, Nav, Container, NavDropdown, Dropdown} from 'react-bootstrap'

import Logo from './logo.svg';  //  src/images

function WelcomeNote(props) {
    


  
    return (




		


   
    <div className="top-desc-container-2">
		<div id="vision-statement">Quick Way To Get Access To Portfolio Reporting Demonstration</div>
    <div id="vision-desc">Provide your email and get demo access. Our Client Services team is always available to help you.<br/>We tailor every financial solution we offer to fit our clients' unique needs.</div>
	</div>
  
		
	 

	  

	



);
}

export default WelcomeNote;
