import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";
//import {Navbar, Nav, Container, NavDropdown, Dropdown} from 'react-bootstrap'

import Logo from './logo.svg';  //  src/images

function Navigation(props) {
    


  
    return (
<div>


<header style={{background:'white'}}>

		
		<div className="top-image-div">
			<a href={process.env.REACT_APP_WATAR_URL}><img src="/assets/watar_logo.svg" style={{width: '139px',
height: '80px',
objectFit: 'contain',
objectPosition: 'center center'}}/></a>
		</div>

		 <div id="menu_area" className="menu-area">
			    <center>
					<nav className="navbar navbar-light navbar-expand-lg mainmenu">
						<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
						</button>
						<div className="collapse navbar-collapse" id="navbarSupportedContent">
							<ul className="navbar-nav mr-auto">
								<li>
								  <a href="https://www.watarpartners.com/" id="navbarDropdown" >Who We Are</a>
								</li>
								<li>
								  <a href="https://www.watarpartners.com/#adi_page1001_1_138" id="navbarDropdown" >Vision & Mission</a>
								</li>
                <li>
								  <a href="https://www.watarpartners.com/#comp-jub0maso3" id="navbarDropdown" >Advisory</a>
								</li>
								<li>
								  <a href="https://www.watarpartners.com/#comp-jub0maso19" id="navbarDropdown" >Governance and Representation</a>
								</li>
								<li>
								   <a href="https://www.watarpartners.com/#comp-jub0masp7" id="navbarDropdown" >The Partners</a>
								</li>
								{ /* 
								<li>
									<a href={process.env.REACT_APP_WATAR_IPS_URL} id="navbarDropdown" >Risk Assessment</a>
								</li>

								<li>
									<a href={process.env.REACT_APP_WATAR_CAREER_URL} target={'_blank'} id="navbarDropdown" >Career Portal</a>
								</li>

								*/}
								
								<li >
									<a href='/demoaccess' id="navbarDropdown" >Portfolio Reporting Demo</a>
								</li>
								
								
								<li>
									<a href="https://www.watarpartners.com/#comp-jub0masq5" id="navbarDropdown" >Contact</a>
								 </li>
							</ul>
						</div>
					</nav>
				</center>
		 </div>
        


    </header>
		

</div>
);
}

export default Navigation;
