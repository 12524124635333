import http from "../http-common";

class IPSDataService {
  getAll() {
    return http.get("/ips");
  }

  get(id) {
    return http.get(`/ips/${id}`);
  }

  

  create(data) {
    return http.post("/ips", data);
  }

  signup(data) {
    return http.post("/ips/signup", data);
  }

  signupDemoAccess(data) {
    return http.post("/ips/signupDemoAccess", data);
  }

  LoginDemoAccess(data) {
    return http.post("/ips/LoginDemoAccess", data);
  }

  AdminLogin(data) {
    return http.post("/ips/AdminLogin", data);
  }

  ExtendRequestDemoAccess(data) {
    return http.post("/ips/ExtendRequestDemoAccess", data);
  }

  VerificationDemoAccess(data) {
    return http.post("/ips/VerificationDemoAccess", data);
  }

  signupReport(data) {
    return http.post("/ips/signupReport", data);
  }
  
  SendEmailToUser(data) {
    return http.post("/ips/SendEmailToUser", data);
  }

  GeneratePdfAndSave(data) {
    return http.post("/ips/GeneratePdfAndSave", data);
  }

  GenerateCustomPdfAndSave(data) {
    return http.post("/ips/GenerateCustomPdfAndSave", data);
  }



  update(id, data) {
    return http.put(`/ips/${id}`, data);
  }



  findByTitle(title) {
    return http.get(`/ips?title=${title}`);
  }

  findAllPublished() {
    return http.get(`/ips/published`);
  }

  getKnowledgeLevelList() {
    return http.get(`/ips/getKnowledgeLevelList`);
  }

  getPortfolioSizeList() {
    return http.get(`/ips/getPortfolioSizeList`);
  }

  getStateOfLifeList() {
    return http.get(`/ips/getStateOfLifeList`);
  }

  getSectionQuestionsList(id) {
    return http.get(`/ips/getSectionQuestionsList/${id}`);
  }

  getTypesOfQuestionares() {
    return http.get(`/ips/getTypesOfQuestionareslList`);
  }

  getSectionQuestionsListNew(id,type) {
    return http.get(`/ips/getSectionQuestionsListNew/${id}/${type}`);
  }
// ///////////////

getModelTypes(values,type, modeltypevalue) {
  return http.get(`/ips/getModelTypes/${values}/${type}/${modeltypevalue}`);
}
// //////////////






getAllModelTypes(type, modeltypevalue) {
  return http.get(`/ips/getAllModelTypes/${type}/${modeltypevalue}`);
}


getCustomizedRetunValues() {
  return http.get(`/ips/getCustomizedRetunValues`);
 
}


  

  getSectionOptionList(id) {
    return http.get(`/ips/getSectionOptionList/${id}`);
  }

  ipssearch(data) {
    return http.post("/ips/searchJob", data);
  }

  ipsbycategory(category) {
    return http.get(`/ips/ipsbycategory/${category}`);
  }

  sendmessage(data) {
    return http.post("/ips/sendmessage", data);
  }

  getAdminconfig() {
    return http.get("/ips/adminconfig");
  }

  forgetpassword(data)
  {
    return http.post("/ips/forgetpassword", data);
  }

  // get All portal Users
  GetAllPortalUsers()
  {
    return http.get(`/ips/GetAllPortalUsers`);
  }
  
  UpdateTokenStatus(data) {
    return http.post("/ips/UpdateTokenStatus", data);
  }
  
  UpdateTokenExpiryDate(data) {
    return http.post("/ips/UpdateTokenExpiryDate", data);
  }

  
}

export default new IPSDataService();
