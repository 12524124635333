import React, { Component } from "react";
import IPSDataService from "../services/ips.service";


export default class Job extends Component {
    constructor(props) {
      super(props);
      this.state = {
        currentConfig: {
          adminemail: '',
          address1: "",
          address2: "",
          facebooklink:"",
          linkedinlink:"",
          twiterlink:"",
          published: false,
        },
        message: ""
      };
    }
  
    componentDidMount() {
      this.getAdminconfig();
    }


    getAdminconfig() {
      IPSDataService.getAdminconfig()
        .then(response => {
          this.setState({
            currentConfig: response.data[0]
          });
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    }



    render() {
      const { currentConfig } = this.state;
  return (

    <footer className="site-footer">

<div className="container">
          <div className="row">

            
            <div className="col-sm-3 col-md-3 col-lg-3 col-md-3 col-xs-12">
              <h6>Useful Links</h6>
              <ul className="footer-links"> 
              <li className="footer-menu-item"><a href={"/demoaccess"}>Portfolio Reporting Demo</a></li>
              <li className="footer-menu-item"><a target="blank" href={process.env.REACT_APP_WATAR_URL}>Watar Partners</a></li>
              <li className="footer-menu-item"><a target="blank" href={"https://cs.watarpartners.com/privacypolicy.html"}>Privacy Policy</a></li>
              {/*<li className="footer-menu-item"><a target="blank" href={process.env.REACT_APP_WATAR_CAREER_URL}>Career Portal</a></li> */}
              
             
              </ul>
            </div>
  
            <div className="col-sm-3 col-md-3 col-lg-3 col-xs-12">
                <h6>Riyadh Office</h6>
                
                <p className="text-justify" style={{color: 'white'}}><div dangerouslySetInnerHTML={{__html:currentConfig.address1}}></div></p>

            </div>

            <div className="col-sm-3 col-md-3 col-lg-3 col-xs-12">
                <h6>Dubai Office</h6>
                
                <p className="text-justify" style={{color: 'white'}}><div dangerouslySetInnerHTML={{__html:currentConfig.address2}}></div></p>

            </div>

            <div className="col-sm-3 col-md-3 col-lg-3 col-xs-12">
                <h6>Pakistan Office</h6>
                
                <p className="text-justify" style={{color: 'white'}}><div dangerouslySetInnerHTML={{__html:currentConfig.address3}}></div></p>

            </div>
  
           
  
  
            
          </div>
        
        </div>

		<div className="container">
          <div className="row">
               
            <div className="col-md-12 col-sm-12 col-xs-12">
              <p className="copyright-text"> &copy; <script>document.write(
				  new Date().getFullYear()
				  );</script> All Rights Reserved <a href="https://www.watarpartners.com/" target="_blank">Watar Partners</a>
              </p>
            </div>


            <div className="col-md-12 col-xs-12" hidden>
             
                    <ul class="social-icons" style={{textAlign:'center'}}>
                      <li><a className="linkedin" target="_blank" href={currentConfig.linkedinlink}><img className="social_icons" src="/img/linkedin.png" /></a></li>
                     <li><a className="twitter" target="_blank" href={currentConfig.twiterlink}><img className="social_icons" src="/img/twitter.png" /></a></li>
                     <li><a className="facebook" target="_blank" href={currentConfig.facebooklink}><img className="social_icons" src="/img/fb.png"/></a></li> 
                    </ul>
                 
            </div>

			
  
           
          </div>
        </div>

        
			</footer>

  	
			
      );
    }
    }


