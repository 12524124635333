import React, { Component } from "react";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import "./App.css";


import Navigation from "./components/component.navigation";
import Banner_IPS from "./components/component.banner_IPS";
import Banner_DemoAccess from "./components/component.banner_demoAccess";
import WelcomeNote_IPS from "./components/component.welcomenote_IPS";
import WelcomeNote_demoAccess from "./components/component.welcomenote_demoAccess";
import DemoAccess from "./components/component.DemoAccess";
import IPSform from "./components/component.ipsform";
import IPSformNew from "./components/component.ipsformnew";
import IPSContact from "./components/component.contact";
import IPSPrivacyPolicy from "./components/component.privacypolicy";
import IPSFooter from "./components/component.footer";
import PortfolioSummary from "./components/component.PortfolioSummaryReport";
import Verify from "./components/component.Verify";
import Welcome from "./components/component.WelcomeWatermark";
import DemoAccessUsers from "./components/component.demoAccessUsers";





function App() {
 
  
   
    return (
      <div>
       
       
        
          <Switch>

          <Route exact path={["/ipsform"]} 
            render={() =>
              <div>
              <Navigation />
              <Banner_IPS />
              <WelcomeNote_IPS />
              <IPSform />
              <IPSContact />
              <IPSFooter />
              </div>
            } />


<Route exact path={["/ipsformnew"]} 
            render={() =>
              <div>
              <Navigation />
              <Banner_IPS />
              <WelcomeNote_IPS />
              <IPSformNew />
              <IPSContact />
              <IPSFooter />
              </div>
            } />


<Route exact path={["/privacypolicy"]} 
            render={() =>
              <div>
              <Navigation />
              <Banner_IPS />
              <WelcomeNote_IPS />
              <IPSPrivacyPolicy />
              
              <IPSFooter />
              </div>
            } />


<Route exact path={["/PortfolioSummary"]} 
            render={() =>
              <div>
              <Navigation />
              <Banner_IPS />
              <WelcomeNote_IPS />
              <PortfolioSummary />
              
              <IPSFooter />
              </div>
            } />


<Route exact path={["/demoaccess","/", "/home"]} 
            render={() =>
              <div>
              <Navigation />
              <Banner_DemoAccess />
              <WelcomeNote_demoAccess />
              <DemoAccess />
              <IPSContact />
              <IPSFooter />
              </div>
            } />


<Route exact path={["/demoaccessusers"]} 
            render={() =>
              <div>
              <Navigation />
              <DemoAccessUsers />
             
              <IPSFooter />
              </div>
            } />




<Route exact path={["/verify/:email/:token"]} 
render={() =>
  <div>
 
  <Verify />
  
  </div>
} />


<Route exact path={["/welcome/:email/:token"]} 
            render={props  =>
              <div>
              <Navigation />
              <Welcome{...props} />
              <IPSFooter />
              </div>
            } />



          </Switch> 
        
      </div>
    );
  
}

export default App;



