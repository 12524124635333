import React, { Component } from "react";
import IPSDataService from "../services/ips.service";
import { Modal, Form, Button} from "react-bootstrap";
import ReactWaterMark from "react-watermark-component";



export default class Welcome extends Component {
    constructor(props) {
      super(props);
      this.onChangeCategory = this.onChangeCategory.bind(this);
      
      this.state = {
        currentConfig: {
         
        },
        message: "",
        showModalDemo: false,
        report:"report1",

      };
    }

    openModalDemo()
    {    
        
          this.setState({
          showModalDemo: true,
          });
       
    }

    closeModalDemo()
    {    
        
          this.setState({
          showModalDemo: false
          });
       
    }

    

    onChangeCategory(e) {
      this.setState({
        report: e.target.value
      });
    }

    
    componentDidMount() {
      
    

  

      const url = localStorage.getItem('url');

     

      var data = {
        url: '',
        email:this.props.match.params.email,
        token:this.props.match.params.token,
       
     };

      IPSDataService.VerificationDemoAccess(data)
      .then(response => 
         {
          
          if(response.data!=='')
{
          if(response.data==='ok')
           {
             
             


           }
           else{
             
            window.location.replace(process.env.REACT_APP_WATAR_IPS_URL+"demoaccess");
           }
          }
          else
          {
            window.location.replace(process.env.REACT_APP_WATAR_IPS_URL+"demoaccess");
          }
           
        
          
          })

      .catch(e => 
         {  
          console.log(e);
          });


          if(sessionStorage.getItem('demoPlayed')==='yes')
    {
   
     
   
    
    }
    else
    {
    
      this.setState({
        showModalDemo: true,
        });
      sessionStorage.setItem('demoPlayed', 'yes');
      
    }

  
      
    }

    Logout()
    {
      sessionStorage.removeItem('DemoUserEmail');
      sessionStorage.removeItem('DemoUserPassword');
      sessionStorage.removeItem('DemoUserLogin');

      window.location.replace(process.env.REACT_APP_WATAR_IPS_URL+"demoaccess");
  
  
    }
     
  

    render() {

      const options = {
        chunkWidth: 500,
        chunkHeight: 350,
        textAlign: "middle",
        textBaseline: "bottom",
        globalAlpha: 1,
        font: "1.5vw Microsoft Yahei",
        rotateAngle: 0.5,
        fillStyle: "#C6C5C9"
      };
  
  return (

    
   
    <ReactWaterMark waterMarkText={sessionStorage.getItem('DemoUserEmail')} options={options}>
    
<div className="row" style={{padding:'0px',textAlign:'center',margin:'5px 0px'}}>

<div class="col-md-1 offset-md-3 col-sm-2 col-xs-12">
<Button class="form-control" variant="primary" size="sm" style={{padding:'7px 7px', marginLeft:'2px', float:'left'}} onClick={e => this.openModalDemo()}> <i class="fa fa-question-circle-o"></i> Tutorial
                           </Button>
  </div>

<div class="col-md-4   col-sm-4 col-xs-12">
                  
				  
                    <select class="form-control" id="category"
                required
                value={this.state.report}
                onChange={this.onChangeCategory}
                name="category">
                      <option disabled selected value="">Select Report</option>
                      <option value="report1">Client Portfolio Report-Demo Portal</option>
                      <option value="report3">Client Portfolio Monthly Report-Demo Portal</option>
                      {/*<option value="report2">Demo - Portfolio Summary</option> */}
					            
                      
                    </select>
                    
                    
                  		

                  							
                </div>

                <div class="col-md-1 col-sm-2 col-xs-12" style={{textAlign:'center'}}>
                  
                 
                    <Button class="form-control" variant="danger" size="sm" style={{padding:'7px 7px',float:'left'}} onClick={e => this.Logout()}> <i class="fa fa-power-off "></i> Logout
                           </Button>	&nbsp;
                           
                  </div>

                 
                  <div class="col-md-2 col-sm-4 col-xs-12" style={{textAlign:'center'}}>
                  <p style={{padding:'0px 0px', marginLeft:'2px'}}> <i class="fa fa-user-circle-o"></i> <b>{sessionStorage.getItem('DemoUserName')} </b> 
                           {/*<br/> <i class="fa fa-envelope-o"></i> <i>{sessionStorage.getItem('DemoUserEmail')}</i> */}</p>
                           </div>
  

                  <Modal size="lg" dialogClassName="modal-90w" style={{zIndex: '9999'}} show={this.state.showModalDemo} >
      <Modal.Header>
                  <Modal.Title style={{width:'95%'}}>
                     <div className="modal-heading">Watch Quick Demo</div>
                  </Modal.Title>
                 <a style={{width:'5%'}} onClick={this.closeModalDemo.bind(this)}><i className="fa fa-times"></i></a>
               </Modal.Header>
        <Modal.Body>

        <video width="100%" height="100%" controls>
  <source src="/img/Compress Video_1.mp4#t=0.1" type="video/mp4" />

Your browser does not support the video tag.
</video> 

  
          
        </Modal.Body>
        <Modal.Footer>
             
          <Button variant="primary" size="sm"  onClick={this.closeModalDemo.bind(this)}>
            Skip 
          </Button>
          
        </Modal.Footer>
      </Modal>

                  

      
                
{this.state.report=='report1'?(
<iframe title="Client Portfolio Report-Demo Portal" class='report-frame' src={process.env.REACT_APP_SERVER_ROOT_URL+'/showreport'} frameborder="0" allowFullScreen="true"
></iframe> ):''}

{this.state.report=='report2'?(
<iframe title="Demo - Access" class='report-frame' src={process.env.REACT_APP_SERVER_ROOT_URL+'/showreport2'} frameborder="0" allowFullScreen="true"
></iframe> ):''} 

{this.state.report=='report3'?(
  <>
 {/*<iframe title="Client Portfolio Monthly Report - Demo Portal" class='report-frame'   src={process.env.REACT_APP_SERVER_ROOT_URL+'/Client Portfolio Monthly Report - Demo Portal.pdf?page=hsn#toolbar=0'} frameborder="0" height="100%" scrolling="auto" ></iframe> */}
 {/*<iframe    title="Client Portfolio Monthly Report - Demo Portal" class='report-frame'      src={'http://docs.google.com/gview?url=https://wp-prospect-serverportal.azurewebsites.net/Client Portfolio Monthly Report - Demo Portal.pdf&embedded=true'}  frameborder="0"> </iframe> */}

{/*
 <object class='report-frame' data={process.env.REACT_APP_SERVER_ROOT_URL+'/Client Portfolio Monthly Report - Demo Portal.pdf'} width="400" height="500" type="application/pdf">
    <iframe class='report-frame' src={'http://docs.google.com/gview?url=https://wp-prospect-serverportal.azurewebsites.net/Client Portfolio Monthly Report - Demo Portal.pdf&embedded=true'} frameborder="0"></iframe>
</object>
*/}


<object class='report-frame' data={process.env.REACT_APP_SERVER_ROOT_URL+'/Client Portfolio Monthly Report - Demo Portal.pdf'} type="application/pdf">
    <embed class='report-frame' src={process.env.REACT_APP_SERVER_ROOT_URL+'/Client Portfolio Monthly Report - Demo Portal.pdf'} type="application/pdf" />
        <p>Your browser does not support PDFs view. Please download the PDF to view it: <a href={process.env.REACT_APP_SERVER_ROOT_URL+'/Client Portfolio Monthly Report - Demo Portal.pdf'}>Download Report</a>.</p>
    
</object>


 </>
 ):''} 


    

</div>





      </ReactWaterMark>


  
      );

      
    }
    }


