import React, { Component } from "react";
import IPSDataService from "../services/ips.service";
import {  Button} from "react-bootstrap";

export default class Successful extends Component {
    constructor(props) {
      super(props);

     
      this.state = {
        currentConfig: {
          fname: '',
          lname: "",
          email: "",
          password:"",
          contact:"133",
          published: false,
        },
        message: ""
      };
    }


    componentDidMount() 
    {  
        this.SuccessCheckToken();
    }

   
    SuccessCheckToken()
    {
      document.getElementById("success").style.display="none";
            document.getElementById("Error").style.display="none";
     
      const UrlAfter_ = window.location.href.substring(window.location.href.indexOf('verify') );
     
      var splitStr= UrlAfter_.split('/')
      var email =splitStr[1];
      var token= splitStr[2];
       

       
     

      var data = {
        url: window.location.href,
        email:email,
        token:token,
       
     };

      IPSDataService.VerificationDemoAccess(data)
      .then(response => 
         {
          
          if(response.data!=='')
{
          if(response.data==='ok')
           {
             
             
            document.getElementById("success").style.display="block";

           }
           else if(response.data==='ok-admin-pending')
           {
             
             
            document.getElementById("success-admin-pending").style.display="block";

           }
           else{
             
            document.getElementById("Error").style.display="block";
           }
          }
           
        
          
          })

      .catch(e => 
         {  console.log(e);
          });
   
        
    }

 



    render() {
  
  return (

    
   
   
<>
<div id = "success" style={{display: "none"}}>
<div class="container">
   <div class="row">
      <div class="col-md-6 mx-auto mt-5">
         <div class="SuccessDiv">
            <div class="SuccessDiv_header">
               <div class="SuccessCheck"><i class="fa fa-check fa-3x" aria-hidden="true"></i></div>
            </div>
            <div class="SuccessContent">
               <h2>Email Verified Successfully!</h2>
               <p>Now you can login using provided email and password. </p>
               <a href={process.env.REACT_APP_WATAR_IPS_URL+'demoaccess?login'}>Login</a>
            </div>
            
         </div>
      </div>
   </div>
</div>
</div>

<div id = "success-admin-pending" style={{display: "none"}}>
<div class="container">
   <div class="row">
      <div class="col-md-6 mx-auto mt-5">
         <div class="SuccessDiv">
            <div class="SuccessDiv_header">
               <div class="SuccessCheck"><i class="fa fa-check fa-3x" aria-hidden="true"></i></div>
            </div>
            <div class="SuccessContent">
               <h2>Email Verified Successfully!</h2>
               <p>One of our representatives will be in contact with you shortly.</p>
               <a href={process.env.REACT_APP_WATAR_IPS_URL+'demoaccess'}>Go Back</a>
            </div>
            
         </div>
      </div>
   </div>
</div>
</div>

<div id = "Error" style={{display: "none"}}>
<div class="container">
   <div class="row">
      <div class="col-md-6 mx-auto mt-5">
         <div class="Failure">
            <div class="Failure_header">
               <div class="check"><i class="fa fa-exclamation-triangle fa-3x" aria-hidden="true"></i></div>
            </div>
            <div class="content">
               <h2>Opps ! Something Went Wrong</h2>
               <p>Looks like something went wrong. Expired or invalid token, please try again. </p>
               <a href={process.env.REACT_APP_WATAR_IPS_URL+'demoaccess'}>Go Back</a>
            </div>
            
         </div>
      </div>
   </div>
</div>
</div>


    </>
			


  
      );
    }
    }


