import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";
//import {Navbar, Nav, Container, NavDropdown, Dropdown} from 'react-bootstrap'

import Logo from './logo.svg';  //  src/images

function Banner(props) {
    


  
    return (
<div>



		
		<div className="image-container">
			<img className='top-banner'  width="100%" src="/assets/banner.svg"/>
			<div className="image-text-block"  >
			<div id="banner-head">
			   Risk Profile Questionnaire
			</div>	
			</div>
		</div>



</div>
);
}

export default Banner;
