import React, { Component } from "react";



function TermsAndConditions(props) {
    return (
        <div>
			<div class="clearfix"></div>

        

<hr/>
            <section class="service-area section-gap" id="service" style={{background:"white"}}>
				<div class="container">
					<div class="row d-flex justify-content-center">
						<div class="col-md-8 pb-40 header-text">
							
							
						</div>
					</div>
					<div class="row" style={{marginTop:'10px'}}>
						<div class="col-lg-12 col-md-12">
							<div class="single-box">
								<p style={{textAlign:'right'}}><i>Last Updated: 20 April 2022</i></p>
								<p>
								<br/>
								The following entities:<br/><br/>
•	WATAR PARTNERS FOR TRADING COMPANY LLC. incorporated under the laws Kingdom of Saudi Arabia having its registered office at Masharik Tower, 3rd Floor King Fahad Road, Al Moatamarat District P.O. Box 10215 Riyadh 11433 Kingdom of Saudi Arabia.<br/><br/>
•	WATAR MANAGEMENT CONSULTANCY LLC, a company incorporated under the laws of United Arab Emirates, having its registered office at Office 105, 1st Floor, Offices 5, One Central, Trade Center 1, Dubai, United Arab Emirates.<br/><br/>
•	WATAR PARTNERS &amp; CO. (PRIVATE) LIMITED, a company incorporated under the laws of Pakistan having its registered office at Office #19, First Floor, Pak Land Business Center, I-8 Markaz, Islamabad, Pakistan.<br/><br/>
(collectively referred to herein as “Watar Partners”, “Watar”, “We”, and “Us”)<br/><br/>
are committed to protecting your privacy and applying data protection and privacy laws in relation to your use of Our Risk Profiling Portal (Risk Profiling Portal). Any personal information that you provide via the Risk Profiling Portal will be used and retained in accordance with this Privacy Policy.
This Privacy Policy is intended to inform you of the privacy practices applicable to the Risk Profiling Portal, and to explain how We collect, store, use, transfer and disclose your personal information. We also describe the kinds of information that We collect from you.
By accessing and using the Risk Profiling Portal and/or by submitting personal information to Watar through any other means, you agree to the processing of your personal data in the manner provided for in this Privacy Policy.</p><br/>

								<h3>HOW DOES THE RISK PROFILING PORTAL WORK?</h3>
<p>Text goes here...</p><br/>
<h3>WHICH INFORMATION IS CONSIDERED PERSONAL INFORMATION?</h3>
<p>Personal information or personal data is information that can be used to identify you. Personal information includes, for example, your name, contact information, email address, identity number, and information relating to your education, criminal and/or employment history. Information that does not identify a particular individual, such as statistical information, is not personal information and is not covered by this Privacy Policy.</p><br/>



<h3>WHAT TYPE OF INFORMATION DOES WATAR PARTNERS COLLECT?</h3><br/>
<b>INVESTOR INFORMATION</b><br/> 
<p>If you peform risk profiling through our Risk Profiling Portal, you will be asked to provide your contact details.</p> 
<b>CONTACT INFORMATION</b><br/> 
<p>You will also be asked to provide your contact details and your profile to enable us to contact you regarding the status of your application.</p><br/>
<b>HOW IS YOUR PERSONAL INFORMATION USED?</b><br/>
<p>Only personal information or data that is necessary for the purposes stated in this Privacy Policy will be collected and used by Watar. </p><br/>




<h3>CROSS-BORDER TRANSFERS OF PERSONAL INFORMATION</h3>
<p>Personal information that is collected by Watar may be stored and processed outside your country of residence, including Saudi Arabia, UAE or any other country in which Watar Partners or its affiliates, subsidiaries or agents maintain facilities. These other countries may be subject to different laws or requirements regarding the protection of personal information from those that apply in Saudi Arabia and UAE. In such cases, Watar Partners will take steps to ensure that your personal information is adequately protected. These steps may include concluding appropriate agreements or requiring that appropriate technical, operational or security measures are put in place by any person to which your personal information is transferred. By using the Risk Profiling Portal, you consent to the transfer of your personal information outside of your country.</p><br/>


<h3>COLLECTING THE DATA OF MINORS</h3>
<p>Watar Partners does not knowingly collect any information from persons under the age of 18 who do not have the consent of their parents or guardians. Our databases may nevertheless contain personal data of children under 18 because it is not always possible for us to precisely determine the age of the user.</p><br/>


<h3>WHAT INFORMATION MAY BE DISCLOSED TO THIRD PARTIES?</h3><br/>
<b>AUTHORIZED THIRD PARTIES</b><br/>
<p>Watar Partners may share your personal information with other companies in its group, including affiliates and subsidiaries, for the purposes of filling job vacancies.</p><br/>
<b>SERVICE PROVIDERS</b><br/>
<p>Watar Partners may share personal information with other service
providers, or professional advisors who are bound by professional obligations of confidentiality.</p><br/>
<b>MANDATORY DISCLOSURE</b><br/>
<p>It may be necessary, to comply with any law, for the purposes of litigation, or to comply with requests from public and governmental authorities within or outside your country of residence for Watar Partners to disclose your personal information. </p><br/>
<b>NECESSARY DISCLOSURE</b><br/>
<p>Watar Partners may also disclose information about you if we determine that disclosure is reasonably necessary to enforce our terms and conditions or protect our operations. Additionally, in the event of a re-organization, merger, or sale we may transfer any and all personal information we collect to the relevant third party.</p><br/>


<h3>CONTROL OF YOUR PERSONAL INFORMATION</h3>
<p>When you apply for a job with Watar Partners through the third-party service provider’s site, or otherwise give us personal information, we will not share that information with third parties without your permission, other than in the limited instances listed above. Your personal information that is collected by Watar will also only be used for the purposes described in this Privacy Policy.</p><br/>
<h3>ACCESS TO YOUR PERSONAL INFORMATION</h3>
<p>We take reasonable steps to ensure that the personal data we collect, and store is accurate, complete and up-to-date. We also endeavour as best we can to delete any out-of-date, incorrect or unnecessary personal data. We will provide you with the means to ensure that your personal information is accurate, complete and up to date. You can access your personal information by contacting Watar Partners using the contact information set out in this Privacy Policy.
Please remember that it is your responsibility to provide us with correct details, especially correct contact details, as well as to update the personal data you previously provided to us in the event of any changes.</p><br/>

<h3>SECURITY OF YOUR PERSONAL INFORMATION</h3>
<p>Watar Partners is committed to protecting the security of your personal information and will take all reasonable steps to secure the personal information that you submit to us, including your CV and candidate profile. We use a variety of security technologies and physical, electronic and managerial procedures to protect the personal information that we have collected, or that you have provided, from unauthorized access, use or disclosure. This will minimize the risk of loss, misuse, alteration, disclosure and unauthorized access of your information that is under our control.</p><br/>
<h3>RETENTION OF YOUR PERSONAL INFORMATION</h3>
<p>We will keep your personal information only for as long as is necessary to achieve the purpose for which the information was collected.</p><br/>
<h3>CHANGES TO THIS PRIVACY POLICY</h3>
<p>Watar Partners may change or update this Privacy Policy from time to time. When we do, we will also revise the “last updated” date at the top of the Privacy Policy. You will be required to agree to the revised Privacy Policy the next time you apply for a job through the Risk Profiling Portal. We also recommend that you revisit this Privacy Policy from time to time when you access and use the Risk Profiling Portal, to learn of any such changes to the Privacy Policy.</p><br/>




							</div>
						</div>
					
					
					
						
												
					</div>
				</div>	
			</section>


          


           
						


			
			
</div>

    );
}
export default TermsAndConditions;