import React, { Component } from "react";
import { Thead, Tbody, Tr, Th, Td, Table} from 'react-super-responsive-table'
import IPSDataService from "../services/ips.service";
import {Button,Modal  } from 'react-bootstrap';
//import Moment from 'moment';


export default class demoAccessUsers extends Component {

  constructor(props) {
    super(props);

    this.getAllUsers = this.getAllUsers.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChangeloginPassword = this.onChangeloginPassword.bind(this);
    this.onChangeloginEmail = this.onChangeloginEmail.bind(this);

   this.state={
    showModal: false,
    loginPassword:'',
    loginEmail:"",
    loginmessage: "",
    AdminLoginshowModal:false,
    AllUsersArray: [],
    editableUserId: '',
    editableUserEmail: '',
    startDate: new Date(),
   };
  }

  componentDidMount() {
    // this.getAllUsers();
    

if(sessionStorage.getItem('Auto Login')==='true')
    {
   
    this.AdminLogin(sessionStorage.getItem('Name'), sessionStorage.getItem('Password'));
   
    
    }
    else
    {
    
      this.AdminLoginModal();
    }

  }

handleChange(e) {
  
  this.setState({
    startDate: e.target.value+" 00:00:00"
  })
}
onFormSubmit(e) {
  e.preventDefault();
  console.log(this.state.startDate)
}

  EditStatus(id,email){

    var data = {
      id: id,
      email:email
      
   };
    IPSDataService.UpdateTokenStatus(data)
    .then(response => 
       {
        this.getAllUsers();
       
          this.setState({

            showModal: false,
           });

       
           
        })

       

        
 
    .catch(e => 
       {
          this.setState({
         
       });
          console.log(e);
        });

  }
  // ////////////////
 

  AdminLogin(email, password)
  {
   
    this.setState({
                        
      loginmessage: ""
    
        });
  
        
  
        if(email==="" || password==='')
        {    
   
          this.setState({
                        
            loginmessage: 'Please fill form',
          
              });

              if(!(this.state.AdminLoginshowModal)){

                this.setState({

                  AdminLoginshowModal:true
                          
                 
                
                    });
              }
              return false;
        }
       
      
       const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
       if(email!=="")
       {
          if (reg.test(email) === false){
            this.setState({
                          
              loginmessage: 'Invalid email address',
            
                });

                if(!(this.state.AdminLoginshowModal)){

                  this.setState({

                    AdminLoginshowModal:true
                            
                   
                  
                      });
                }



                return false;
          }
  
       }
  
       
  
      
       var data = {
        email: email,
        password: password,
       
     };
       
       
       IPSDataService.AdminLogin(data)
          .then(response => 
             {
               console.log(response.data);
               
               if(response.data==='Success')
               {
              

               
                sessionStorage.setItem('Name', email);
                sessionStorage.setItem('Password', password);
                sessionStorage.setItem('Auto Login', 'true');
                
                this.getAllUsers();
                this.AdminLogincloseModal();
                  
                this.setState({
                          
                  loginmessage: ""
                
                    });
               }
              
               else 
               {

                

                if(!(this.state.AdminLoginshowModal)){

                  this.setState({

                    AdminLoginshowModal:true
                            
                   
                  
                      });
                }
                  
                
                this.setState({

                  
                          
                  loginmessage: "Invalid email or password."
                
                    });
                  
               }
               
              
              })
  
          .catch(e => 
             {  console.log(e);
              });
  }
// ///////////////////////////////////
Logout()
  {
    sessionStorage.removeItem('Name');
    sessionStorage.removeItem('Password');
    sessionStorage.removeItem('Auto Login');


    this.setState({
      AllUsersArray: [],
      AdminLoginshowModal:true,
                        
      loginmessage: ""
    
        });
  }

// //////////////////////////////////
EditDate(date){
  
   
  //var date=Moment(date).format('YYYY-MM-DD HH:mm:ss');
  var date=date.substring(0, 10)+' 00:00:00';

  var data = {
    date:  date,
    id: this.state.editableUserId,
    email: this.state.editableUserEmail
    
 };

 

  IPSDataService.UpdateTokenExpiryDate(data)
  .then(response => 
     {
      this.getAllUsers();
     
        this.setState({
          
          showModal: false,
         });
      })

  .catch(e => 
     {
        this.setState({
        
     });
        console.log(e);
      });

}



// /////////////////


onChangeloginPassword(e)
{
 this.setState({
    loginPassword: e.target.value
 });
}

onChangeloginEmail(e)
{
 this.setState({
    loginEmail: e.target.value
 });
}


AdminLoginModal()
  {
    
     this.setState({
     AdminLoginshowModal: true,
 
     
     });
     
  }


  AdminLogincloseModal()
  {
     this.setState({
      AdminLoginshowModal: false,
     
     });
     
  }


  editModal(id,email)
  {
    
     this.setState({
     showModal: true,
     editableUserId:id,
     editableUserEmail:email,
     
     });
     
  }

  closeModal()
      {
         this.setState({
         showModal: false,
         
         });
         
      }

  getAllUsers()
  {
    
    IPSDataService.GetAllPortalUsers()
    .then(response => 
       {
        let testArray=[];
        for(let n=0; n<response['data'].length; n++)
        {
          testArray.push(response['data'][n])
        }
       
          this.setState({
            AllUsersArray: testArray
           });
           console.log(this.state.AllUsersArray)
        })

       

        
 
    .catch(e => 
       {
          this.setState({
         
       });
          console.log(e);
        });
  }

  render() {
       
    const { AllUsersArray } = this.state;
    

    return(

      
      <div class="row" style={{margin:'4% 0%'}}>
        <div class="container">
    <div class="row">
      <div class="col-md-12">
      <div class="card card-secondary">
          <div class="card-header">
           <h3 class="card-title">Users Management <Button variant="danger" size="sm" style={{float:'right',display:this.state.AdminLoginshowModal==true? 'none':'block'}} onClick={e => this.Logout()}> <i class="fa fa-power-off "></i>
                           </Button></h3>   
          </div>
        
          <div class="card-body">
      
          <Table className="table table-borderless asset-table">
                                   <Thead>
                                     <Tr>
                                     <Th>ID</Th>
                                     <Th>Name</Th>
                                     <Th>Email</Th>
                                     <Th>Type</Th>
                                     <Th>Status</Th>
                                     <Th>Expiry</Th>
                                     <Th>Action</Th>
                                     </Tr>
                                   </Thead>


                                   <Tbody>

                                
                                
                                     
                                   
                                    
                                   { this.state.AllUsersArray.map((List, counting)=>(
    
                         <Tr style={{marginBottom:'2px'}}>
                           <Td>{AllUsersArray[counting].id}</Td>
                           
                           <Td>{AllUsersArray[counting].firstname}</Td>
                           <Td>{AllUsersArray[counting].email}</Td>
                         
                           <Td>{AllUsersArray[counting].usertype}</Td>
                           <Td>{AllUsersArray[counting].tokenstatus!=='Verified'?
                           (<Button size="sm" onClick={e => this.EditStatus(AllUsersArray[counting].id,AllUsersArray[counting].email)}>
                            <i class="fa fa-check"></i></Button>):'' }{AllUsersArray[counting].tokenstatus}
                           
                          
                           </Td>
                           
                           
                           
                           <Td>{AllUsersArray[counting].tokenexpiry.substring(0,10) } </Td>


                          <Td>
                            <Button size="sm"
                            onClick={e => this.editModal(AllUsersArray[counting].id,AllUsersArray[counting].email)} 
                            >
                              Edit</Button></Td>
                          </Tr>
                         
                        ))} 
                        
                        
                        
                                   </Tbody>
                                 </Table>
                                 </div>
                                 
                                 </div>
                                
                                 </div></div>

                                 </div>
       
       
                                 <Modal show={this.state.showModal} backdrop="static" onHide={this.closeModal.bind(this)}>
               <Modal.Header>
                  <Modal.Title style={{width:'95%'}}>
                     <div className="modal-heading">Edit Expiry Date</div>
                  </Modal.Title>
                  <a style={{width:'5%'}} onClick={this.closeModal.bind(this)}><i className="fa fa-times"></i></a> 
               </Modal.Header>
               <Modal.Body >


             
               <div class="row">	
 <div class="form-group col-md-12">
                <label>Expiry Date</label>
 <input className="form-control" type="date"
 
              defaultValue={ this.state.startDate }
              onChange={ this.handleChange }
              dateformat="Y-M-d"
          />

<br></br>
<br></br>
<Button onClick={e => this.EditDate(this.state.startDate)} > 
Update</Button>
</div>
                                    </div>
                
               
               </Modal.Body>
              
            
            </Modal>
{/* /////////////////// huda */}
            {/* //// */}
            <Modal show={this.state.AdminLoginshowModal} backdrop="static" onHide={this.AdminLogincloseModal.bind(this)}>
               <Modal.Header>
                  <Modal.Title style={{width:'95%'}}>
                     <div className="modal-heading">Admin Login</div>
                  </Modal.Title>
                  {/* <a style={{width:'5%'}} onClick={this.AdminLogincloseModal.bind(this)}><i className="fa fa-times"></i></a>  */}
               </Modal.Header>
               <Modal.Body >


             
               <div class="row">	


               <div class="form-group col-md-12">
                <label>Email*</label>
                <input 
               
               type="email"
               placeholder="Enter your email"

               className="form-control" value={this.state.loginEmail}  onChange={this.onChangeloginEmail} id="loginEmail" 
               autocomplete="off"
               />
          </div>

 <div class="form-group col-md-12">
          <label>Password*</label>
          <input 
                type="password" placeholder="Enter your password"
    
                className="form-control" value={this.state.loginPassword}  onChange={this.onChangeloginPassword} id="loginPassword"
                />

          </div>

<br></br>
<br></br>
<div class="form-group col-md-12">
<Button onClick={e => this.AdminLogin(this.state.loginEmail, this.state.loginPassword)} > 
Login</Button>

<br/>
                <p style={{color:'red',fontSize:'13px'}} dangerouslySetInnerHTML={{__html:this.state.loginmessage}}></p>

</div>
          </div>                        
                
               
               </Modal.Body>
              
            
            </Modal>
            
            
       
       
        </div>
        
        
        );
    }
    }
            
       





